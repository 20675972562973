import React from 'react';

import './normalize.css';
import './bootstrap.css';
import './main.css';
import './style.css';

import imgLogo from './media/logo.png';
import imgSplash from './media/splash.jpg';
import imgPages from './media/pages.png';
import img100years from './media/100years.jpg';
import imgYourStory from './media/your_story.jpg';
import imgIntro from './media/intro.png';

function App() {
  return (
    <>

    <div id="menu_bar">
      <div className="container">

        <a href="#">
          <span id="logo">
            <img src={imgLogo} alt="" />
          </span>
        </a>

        <div className="navbar">
          <ul className="nav">
            <li><a id="cover_splash_link" href="#cover_splash">Cover</a></li>
            <li><a id="get_book_link" href="#get_book">Get the Book</a></li>
            <li><a id="intro_link" href="#intro">Introduction</a></li>
            <li><a id="preface_link" href="#preface">Preface</a></li>
            <li><a id="id_story_form_link" href="#id_story_form">Fill in the Story</a></li>
          </ul>
        </div>

      </div>
    </div>

    <div id="cover_splash">
      <img src={imgSplash} alt="" className="cover" />
    </div>

    <div id="get_book">
        <div className="container">

            <img src={imgPages} alt="" className="bottom-20" />

            <p>Be the first to order a copy of the book.  Provide your email below and we'll let you know when the book goes on sale.</p>

            <form>
              <h2>Get the Book</h2>
              <input id="id_email" type="text"  name="email" placeholder="Your Email Address..." />
              <input id="submit_subscribe" type="submit" value="Notify Me" /> <div id="subscribe_result"></div>
            </form>

        </div>
    </div>

    <div className="container intropreface_container">

        <div id="intro" className="row intro_bg">

          <div className="span7">
            <h3>A Fresh Start</h3>
            <h2>Introduction</h2>
            <p>This book was assembled in 2012-2013 by Ryan Phelan, Stewart Brand, and Shellei Addison as a tribute to <span className="i">Mirene</span>’s one hundred years of fortitude.</p>
            <p>Initial historical research began in 1982 at the Coos Historical and Maritime Museum in North Bend, Oregon and with a visit to an extraordinary amateur maritime archivist, Victor West, who for decades collected photos of Northwest coast ships.</p>
            <p>In 2012, researchers at the Coos Historical and Maritime Museum turned up additional historical photos and news report that helped chronicle <span className="i">Mirene’s</span> adventures over these past 100 years. Contact was made with the families of those who commissioned and built her in 1912 and they too generously donated photos and family lore to the story. Interviews were made to record personal memories. File boxes of the current owners (Phelan and Brand) were rifled through for pictures they and others had taken since 1982. New photos were commissioned from Dusan Mills.</p>
            <p>In 2012, <span className="i">Mirene</span> also got new paint, new varnish, serious service on her engine room, repairs to a bad leak along the drive shaft, and a general refreshening with which to take on her second century.</p>
          </div>

          <div className="span5 right">
            <img
              src={imgIntro}
              alt=""
              style={{ position: 'relative', marginBottom: 20, left: 20 }}
            />
          </div>

        </div>

        <div id="preface" className="row fancy_bg">

          <div className="span12">

            <img
              src={img100years}
              alt=""
              style={{
                float: 'right',
                marginLeft: 30,
                marginTop: 10,
                marginBottom: 60
              }}
            />

            <h2>Preface</h2>
            <p>The tugboat <span className="i">Mirene</span> still has her original hull and original name from 1912, when she was built in Coos Bay, Oregon, by the Kruse & Banks shipyard as a 64-foot gasoline schooner. Most wooden ships succumb very quickly to mishap, obsolescence, neglect, or rot. Extremely few last a century. When <span className="i">Mirene</span> was being refitted in Sausalito in the 1980s, a waterfront local commented,</p>
            <p className="center" style={{ fontSize: '1.2em', marginBottom: 20 }}>“Some boats have all the luck.”</p>
            <p>Originally destined for work in Alaska as a cannery tender, <span className="i">Mirene</span> wound up working the Oregon and Washington coast carrying cargo and passengers from river to river, surviving going aground, fierce storms at sea, and close calls with fire. Converted to a tugboat with a large diesel engine, she towed log rafts on the Columbia and Willamette rivers and became the founding vessel of The Mirene Company, which ran a fleet of tugs out of Portland Oregon. Later she worked at sea as a fishing boat.</p>

            <img
              src={imgYourStory}
              alt=""
              style={{
                position: 'relative',
                top: -20,
                left: 20,
                float: 'right',
                marginLeft: 30
              }}
            />


            <p>In 1975 a creaking <span className="i">Mirene</span> was sailed down the coast to Sausalito to die. Her engine, steering wheel, and hardware went to salvage, and what was left became a derelict houseboat, part of the rowdy Dredgetown community.</p>

            <p>In 1982 Stewart Brand and Ryan Phelan bought the hulk for $8,000. The abundant maritime talent on the Sausalito waterfront brought <span className="i">Mirene</span> back to life, first as a respectable houseboat on the South Forty Dock, then as a working vessel cruising the Bay Area from Petaluma to the Sacramento Delta to the Pacific Ocean swells she was built for.</p>

            <form id="id_story_form">
                <h3>Fill in the Story</h3>
                <h2>Tell Your Piece of The Story</h2>
                <p>There are many years of <span className="i">Mirene</span>'s s history that are still a mystery.  If you know any great stories about <span className="i">Mirene</span> or have any old photos you'd like to share, we'd love to incorporate them into our book.</p>
                <table>
                  <tbody>
                    <tr>
                        <th><label htmlFor="">Name</label></th>
                        <td><input type="text" name="name" /></td>
                    </tr>
                    <tr>
                        <th><label htmlFor="">Email</label></th>
                        <td><input type="text" name="story_email" /></td>
                    </tr>
                    <tr>
                        <th><label htmlFor="">Your Story</label></th>
                        <td><textarea name="story" /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input id="submit_story" type="submit" /> <div id="story_result" /></td>
                    </tr>
                  </tbody>
                </table>
            </form>

          </div>

        </div>

    </div>

    <div id="footer_bar">
        <div className="container">
            <span className="footer_logo">
                <span className="datestamp">1912-2012</span>
            </span>
            <span className="quote">“Some boats have all the luck.”</span>
            <span className="top"><a href="#"><span className="ss-icon">&#xF500;</span> Back to Top</a></span>
            <span className="publisher"></span>
        </div>
    </div>


















    </>
  );
}

export default App;
